import { Link } from 'gatsby-theme-material-ui';
import { Typography, Box } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

import MainLayout from '../components/MainLayout';
import Block from '../components/Block';
import SmallHero from '../components/SmallHero';
import backgroundImage from '../images/tech/fabio-oyXis2kALVg-unsplash.jpg';

export function Head() {
  return <title>Tech - Veikko Mäkinen</title>;
}

export default function Tech() {
  return (
    <MainLayout>
      <SmallHero title="The Tech Stack" backgroundImage={backgroundImage} overlayColor="#004" overlayOpacity={0.4} />
      <Block>
        <Typography gutterBottom>
          This site is not only a personal website, it is also a software development
          and
          {' '}
          <Link href="https://en.wikipedia.org/wiki/DevOps" target="_blank">DevOps</Link>
          {' '}
          exercise. I am, after all, first and foremost a software developer
          and wanted to have 100% control over the website&apos;s architecture,
          easy-to-setup local, staging and production environments,
          proper version control,
          {' '}
          <Link href="https://en.wikipedia.org/wiki/CI/CD" target="_blank">CI/CD</Link>
          {' '}
          with
          automated testing and automatic publishing.
        </Typography>
        <Typography variant="h3" gutterBottom>
          The Code
        </Typography>
        <Typography gutterBottom>
          For the actual coding framework I chose
          {' '}
          <Link href="https://www.gatsbyjs.com/docs/" target="_blank">Gatsby</Link>
          . It enabled me to focus on frontend only and use tools I was already somewhat familiar
          with, namely,
          {' '}
          <Link href="https://reactjs.org/" target="_blank">React</Link>
          {' '}
          and
          {' '}
          <Link href="https://mui.com/" target="_blank">MUI</Link>
          . For a developer it offers a really nice way of building a website and completely
          fulfilled my requirements regarding control and testability. Most of the code written
          by me is quite blunt page-specific code but I did end up refactoring some of it into
          reusable components with automated tests.
        </Typography>
        <Typography gutterBottom>
          For the
          {' '}
          <Link to="/ideat">Articles</Link>
          {' '}
          I use
          {' '}
          <Link href="https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/" target="_blank">MDX</Link>
          .
        </Typography>
        <Box textAlign="center">
          <StaticImage
            src="../images/tech/veikkofi-editor.png"
            alt="code and tests"
            placeholder="blurred"
            layout="constrained"
            align="center"
            css={css`
              border: 1px #ccc solid;
              max-width: 450px;
            `}
          />
        </Box>
        <Typography variant="h3" gutterBottom>
          The CI/CD
        </Typography>
        <Typography gutterBottom>
          Automatic building, testing and deployment is done with
          {' '}
          <Link href="https://docs.github.com/en/actions" target="_blank">GitHub Actions</Link>
          . They serve my limited needs perfectly and I do not have to add separate
          CI/CD service to my project.
        </Typography>
        <Box textAlign="center">
          <StaticImage
            src="../images/tech/veikkofi-cicd.png"
            alt="CI/CD with Github Workflows"
            placeholder="blurred"
            layout="constrained"
            css={css`
              border: 1px #ccc solid;
              max-width: 450px;
              margin: 5px;
            `}
          />
        </Box>
        <Typography variant="h3" gutterBottom>
          The Server - Staging &amp; Production
        </Typography>
        <Typography gutterBottom>
          The public site (at the time of writing, a public staging version) is run at
          {' '}
          <Link href="https://fly.io/" target="_blank">Fly.io</Link>
          .
          Gatsby itself offers hosting too and - as my site is purely a frontend - I could have
          chosen surge.sh as well but for some reason I like the idea of having control over the
          container the service is running from and I like Fly.io&apos;s user interface and
          scalability options.
        </Typography>
        <Box textAlign="center">
          <StaticImage
            src="../images/tech/veikkofi-flyio.png"
            alt="code and tests"
            placeholder="blurred"
            layout="constrained"
            css={css`
            border: 1px #ccc solid;
            max-width: 450px;
            margin: 5px;
            `}
          />
        </Box>
        <Box textAlign="center" sx={{ m: 2 }}>
          <StaticImage
            src="../images/tech/veikkofi-flyio-stats.png"
            alt="code and tests"
            placeholder="blurred"
            layout="constrained"
            css={css`
            border: 1px #ccc solid;
            max-width: 450px;
            margin: 5px;
            `}
          />
        </Box>
        <Typography variant="h3" gutterBottom>
          The Monitoring
        </Typography>
        <Typography gutterBottom>
          Although I run the site from Fly.io and really do not have to worry about servers
          or infrastructure I do want to have basic monitoring set up. For that purpose I
          picked
          {' '}
          <Link href="https://betteruptime.com/" target="_blank">Better Uptime</Link>
          . Their free tier seems to offer a really nice bang for the (0) $.
        </Typography>
        <Box textAlign="center" sx={{ m: 2 }}>
          <StaticImage
            src="../images/tech/veikkofi-monitoring.png"
            alt="code and tests"
            placeholder="blurred"
            layout="constrained"
            css={css`
            border: 1px #ccc solid;
            max-width: 450px;
            margin: 5px;
            `}
          />
        </Box>
      </Block>
      <Block sx={{ backgroundColor: 'extras.blue.light', color: 'white' }}>
        <Typography variant="h3" color="inherit" gutterBottom id="credits">
          Credits
        </Typography>
        <Typography gutterBottom>
          A huge part of software development nowadays relies on
          open source software and utilities created and maintained by a
          crowd of individuals and companies.
        </Typography>
        <Typography gutterBottom>
          Here I especially want to acknowledge people creating high quality graphics
          and icons for us to use.
        </Typography>
        <ul>
          <li><Link href="https://tabler-icons.io/">Tabler icons</Link></li>
          <li><Link href="https://github.com/lipis/flag-icons">Flag icons</Link></li>
        </ul>
        <Typography variant="h4" color="inherit" gutterBottom>
          Photographs
        </Typography>
        <Typography gutterBottom>
          I have used my own photograps and my profile photographs were taken by
          Mari @
          {' '}
          <Link href="https://www.studiosighthound.fi/">Studio SightHound</Link>
          {' '}
          but I also use images from talented people in
          {' '}
          <Link href="https://unsplash.com/">Unsplash</Link>
          {' '}
          .
        </Typography>
        <ul>
          <li><Link href="https://unsplash.com/@syinq">Susan Q Yin</Link></li>
          <li><Link href="https://unsplash.com/@pawel_czerwinski">Pawel Czerwinski</Link></li>
          <li><Link href="https://unsplash.com/@fabioha">Fabio</Link></li>
          <li><Link href="https://unsplash.com/@artlasovsky">Art Lasovsky</Link></li>
          <li><Link href="https://unsplash.com/@homajob">Scott Graham</Link></li>
        </ul>
        <Typography variant="body2">
          I do my best to keep these lists up to date :)
        </Typography>

      </Block>
    </MainLayout>
  );
}

// export const Head = () => <title>Home Page</title>
