import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SmallHeroLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: { // ~desktop
    minHeight: 250,
    maxHeight: 250,
  },
  [theme.breakpoints.down('md')]: { // ~tablet
    minHeight: 175,
    maxHeight: 175,
  },
  [theme.breakpoints.down('sm')]: { // ~phone
    minHeight: 100,
    maxHeight: 100,
  },
}));

const Background = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
});

const HeroH1 = styled(Typography)(({ theme }) => ({
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  marginTop: 100,
  [theme.breakpoints.up('md')]: { // ~desktop >900px
    fontSize: 48,
  },
  [theme.breakpoints.down('md')]: { // ~tablet <900px
    fontSize: 36,
  },
  [theme.breakpoints.down('sm')]: { // ~phone <600px
    fontSize: 26,
  },
}));

export default function SmallHero({
  title, backgroundImage, overlayColor = '#000', overlayOpacity = 0.7,
}) {
  return (
    <SmallHeroLayoutRoot>
      <Container
        sx={{
          mt: 3,
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        role="banner"
      >
        {/* Increase the network loading priority of the background image. */}
        {backgroundImage ? (
          <img
            style={{ display: 'none' }}
            src={backgroundImage}
            alt="hidden backround"
            data-testid="hero-hidden-img"
          />
        )
          : null }
        <HeroH1 align="center" variant="h1" marked="center" gutterBottom>
          {title}
        </HeroH1>
        <Box
          data-testid="hero-overlay"
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: overlayColor,
            opacity: overlayOpacity,
            zIndex: -1,
          }}
        />
        {backgroundImage ? (
          <Background
            data-testid="hero-background-img"
            sx={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundColor: '#0F141A', // default to ~dark
              backgroundPosition: 'center',
            }}
          />
        ) : null }
      </Container>
    </SmallHeroLayoutRoot>
  );
}

SmallHero.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  overlayColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
};

SmallHero.defaultProps = {
  backgroundImage: null,
  overlayColor: '#000',
  overlayOpacity: 0.7,
};
